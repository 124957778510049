import React from "react";
import IL from "../pageComponents/img/israel.svg";
import US from "../pageComponents/img/usa.svg";

var alt = "";
var currentLanguage = "";
var myFlag = "";

function changeLanguage() {
  // Get elements so we can modify them later on
  const languagePicker = document.getElementsByClassName("countryFlag");
  var currentLanguage = languagePicker[0].lang;

  const navbarWrapper = document.getElementById("navbarWrapper");
  const navBar = document.getElementsByClassName("navbar-nav")[1];
  const nameImgWrapper = document.getElementsByClassName("nameImgWrapper")[0];
  const myTitle = document.getElementsByClassName("myTitle")[0];

  const aboutMeWrapper =
    document.getElementsByClassName("TextAndImgWrapper")[0];
  const aboutMeTitle = document.getElementsByClassName("aboutMeTitle")[0];
  const aboutMeDesc = document.getElementsByClassName("aboutMeDesc")[0];
  const languagesHeader = document.getElementsByClassName("languagesHeader")[0];

  const hobbiesTitle = document.getElementsByClassName("hobbiesTitle")[0];
  const hobbiesText = document.getElementsByClassName("hobbiesText")[0];

  const projectsTitle = document.getElementsByClassName("projectsTitle")[0];
  const projectName = document.getElementsByClassName("projectName");
  const projectDesc = document.getElementsByClassName("projectDesc");

  const contactMe = document.getElementsByClassName("contactMeHeader")[0];

  const footerText = document.getElementsByClassName("footerText")[0];

  switch (currentLanguage) {
    case "IL":
      // Switch to English
      myFlag = US;
      alt = "Flag of the USA";
      currentLanguage = "US";

      navbarWrapper.classList.remove("nav-rtl");
      navbarWrapper.classList.add("dir-ltr");
      navBar.childNodes[0].innerHTML =
        '<span class="navNum">01. </span>About me';
      navBar.childNodes[1].innerHTML =
        '<span class="navNum">02. </span>Projects';
      navBar.childNodes[2].innerHTML =
        '<span class="navNum">03. </span>Contact me';
      navBar.childNodes[3].innerHTML = "Resume";

      nameImgWrapper.classList.remove("dir-rtl");
      nameImgWrapper.classList.add("dir-ltr");
      nameImgWrapper.firstChild.firstChild.classList.remove("text-right");
      nameImgWrapper.firstChild.firstChild.classList.add("text-left");
      nameImgWrapper.firstChild.firstChild.innerHTML = "Kfir Levi";
      myTitle.classList.remove("text-right");
      myTitle.classList.add("text-left");
      myTitle.firstChild.innerHTML = "&lt;&gt;Full Stack developer&lt;/&gt;";

      aboutMeWrapper.classList.remove("dir-rtl");
      aboutMeWrapper.classList.add("dir-ltr");
      aboutMeTitle.innerHTML = "About me";
      aboutMeDesc.outerHTML =
        "<p class=\"aboutMeDesc\">Hi! My name is Kfir, I'm 23 years old and I'm a self-taught Full Stack Developer with a passion for creating elegant web apps.<br><br>With experience in both front-end and back-end development, I have the skills and creativity necessary to design and maintain successful web applications.<br><br>Besides web development sometimes I like to fiddle around with other non-web-related programming languages in order to achieve various tasks and to develop unique projects.<br><br>I'm always on the hunt for new problems to solve, challenges to learn from, and technologies that will revolutionize the web as we know it today.</p>";
      languagesHeader.innerHTML = "My arsenal";

      hobbiesTitle.innerHTML = "Hobbies";
      hobbiesText.innerHTML =
        "In my free time, I like to code 💻, game 🎮, and have fun 😄";

      projectsTitle.innerHTML = "Projects:";
      projectName[0].innerHTML = "Mihtavomat";
      projectName[1].innerHTML = "The Yudnun games";
      projectName[2].innerHTML = "Smart Home";

      projectDesc[0].classList.remove("dir-rtl");
      projectDesc[0].classList.add("dir-ltr");
      projectDesc[1].classList.remove("dir-rtl");
      projectDesc[1].classList.add("dir-ltr");
      projectDesc[2].classList.remove("dir-rtl");
      projectDesc[2].classList.add("dir-ltr");
      projectDesc[0].innerHTML =
        "The Mihtavomat sends a letter of complete nonsense as a generated PDF file to a selected email address.";
      projectDesc[1].innerHTML =
        'Social games management system based on the popular Netflix show "Squid Game".';
      projectDesc[2].innerHTML =
        "Smart home administration app that is connected to a custom electronics circuit, powered by a Raspberry Pi and an Arduino.";

      contactMe.innerHTML = "Let's get in touch";

      // Getting ready for 2024 and beyond
      var currentYear = new Date().getFullYear();
      if (2023 == currentYear) {
        footerText.innerHTML =
          "Designed and developed by Kfir Levi " + currentYear;
      } else {
        footerText.innerHTML =
          "Designed and developed by Kfir Levi 2023 - " + currentYear;
      }
      break;

    case "US":
      // Switch to Hebrew
      myFlag = IL;
      alt = "Flag of Israel";
      currentLanguage = "IL";

      navbarWrapper.classList.remove("dir-ltr");
      navbarWrapper.classList.add("nav-rtl");
      navBar.childNodes[0].innerHTML =
        '<span class="navNum">01. </span>על עצמי';
      navBar.childNodes[1].innerHTML =
        '<span class="navNum">02. </span>פרויקטים';
      navBar.childNodes[2].innerHTML =
        '<span class="navNum">03. </span>צרו קשר';
      navBar.childNodes[3].innerHTML = 'קו"ח';

      nameImgWrapper.classList.remove("dir-ltr");
      nameImgWrapper.classList.add("dir-rtl");
      nameImgWrapper.firstChild.firstChild.classList.remove("text-left");
      nameImgWrapper.firstChild.firstChild.classList.add("text-right");
      nameImgWrapper.firstChild.firstChild.innerHTML = "כפיר לוי";
      myTitle.classList.remove("text-left");
      myTitle.classList.add("text-right");
      myTitle.firstChild.innerHTML = "&lt;&gt;פיתוח אתרים ומערכות&lt;/&gt;";

      aboutMeWrapper.classList.remove("dir-ltr");
      aboutMeWrapper.classList.add("dir-rtl");
      aboutMeTitle.innerHTML = "על עצמי";
      aboutMeDesc.outerHTML =
        '<p class="aboutMeDesc">היי! אני כפיר, בן 23 ואני מתכנת פול-סטאק שנהנה ליצור אתרים ומערכות ייחודיות ואלגנטיות.<br><br>עם ניסיון בפיתוח צד לקוח וצד שרת, יש לי את הניסיון והיצירתיות הדרושים ליצירה ותחזוק של אפליקציות ווב מצליחות.<br><br>חוץ מפיתוח ווב בזמני הפנוי אני אוהב לעסוק בשפות תכנות אחרות על מנת להשיג מטרות שונות ולפתח פרויקטים מיוחדים.<br><br>אני תמיד מחפש אחר בעיות חדשות לפתור, אתגרים ללמוד מהם, וטכנולוגיות מהפכניות שישנו את פני האינטרנט כמו שאנו מכירים אותו כיום.</p>';
      languagesHeader.innerHTML = "הנשקייה שלי";

      hobbiesTitle.innerHTML = "תחביבים";
      hobbiesText.innerHTML =
        "בזמן הפנוי שלי אני אוהב לתכנת 💻, לשחק 🎮, ולהנות 😄";

      projectsTitle.innerHTML = ":פרויקטים";
      projectName[0].innerHTML = "המכתבומט";
      projectName[1].innerHTML = "משחקי היודנון";
      projectName[2].innerHTML = "בית חכם";

      projectDesc[0].classList.remove("dir-ltr");
      projectDesc[0].classList.add("dir-rtl");
      projectDesc[1].classList.remove("dir-ltr");
      projectDesc[1].classList.add("dir-rtl");
      projectDesc[2].classList.remove("dir-ltr");
      projectDesc[2].classList.add("dir-rtl");
      projectDesc[0].innerHTML =
        'שליחת מכתב רשמי מלא בשטויות גמורות כקובץ PDF לכתובת דוא"ל נבחרת.';
      projectDesc[1].innerHTML =
        'מערכת ניהול משחקי חברה בסגנון סדרת הנטפליקס המצליחה "משחקי הדיונון".';
      projectDesc[2].innerHTML =
        "אפליקציית ניהול בית חכם אשר מתממשקת למעגל אלקטרוני בנוי מבוסס Raspberry Pi ו-Arduino.";

      contactMe.innerHTML = "בואו לדבר איתי";

      footerText.innerHTML =
        new Date().getFullYear() + " עוצב ותוכנת על ידי כפיר לוי";

      // Getting ready for 2024 and beyond
      var currentYear = new Date().getFullYear();
      if (2023 == currentYear) {
        footerText.innerHTML = currentYear + " עוצב ותוכנת על ידי כפיר לוי";
      } else {
        footerText.innerHTML =
          "2023 - " + currentYear + " עוצב ותוכנת על ידי כפיר לוי";
      }
      break;

    default:
      break;
  }

  languagePicker[0].src = myFlag;
  languagePicker[0].alt = alt;
  languagePicker[0].lang = currentLanguage;
}

export default function Flag(myFlag) {
  switch (myFlag.myFlag) {
    case "IL":
      myFlag = IL;
      alt = "Flag of Israel";
      currentLanguage = "IL";
      break;

    case "US":
      myFlag = US;
      alt = "Flag of the USA";
      currentLanguage = "US";
      break;

    default:
      break;
  }

  return (
    <div onClick={changeLanguage}>
      <img
        className="countryFlag"
        src={myFlag}
        alt={alt}
        lang={currentLanguage}
      />
    </div>
  );
}

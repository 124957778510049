import React from 'react';
import './css/Aboutme.css';
import HelloSVG from './img/hello.svg';
import Typical from 'react-typical';

export default function Aboutme() {
  return (
    <>
        <div id="aboutme" className="aboutMeWrapper">
          <div className="TextAndImgWrapper d-lg-flex justify-content-center container pt-lg-5">
            <div className="aboutMeText">

              <div className='fit-content'>
                <h1 className="aboutMeTitle">About me</h1>
                <hr className='aboutMeTitleLine' />
              </div>

              <p className='aboutMeDesc'>
                Hi! My name is Kfir, I'm 23 years old and I'm a self-taught Full Stack Developer with a passion for creating elegant web apps.<br/><br/>

                With experience in both front-end and back-end development, I have the skills and creativity necessary to design and maintain successful web applications.<br/><br/>

                Besides web development sometimes I like to fiddle around with other non-web-related programming languages in order to achieve various tasks and to develop unique projects.<br/><br/>

                I'm always on the hunt for new problems to solve, challenges to learn from, and technologies that will revolutionize the web as we know it today.
              </p>

              <div className='fit-content'>
                <h1 className='aboutMeTitle languagesHeader mt-5'>My arsenal</h1>
                <hr className='aboutMeTitleLine' />
              </div>

              <div className="languagesList d-flex">
                <ul>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>Javascript</li>
                  <li>JQuery</li>
                </ul>
                <ul>
                  <li>PHP</li>
                  <li>MySQL</li>
                  <li>ReactJS</li>
                  <li>Wordpress</li>
                </ul>
              </div>

              <div className='fit-content'>
                <h1 className="aboutMeTitle hobbiesTitle mt-5">Hobbies</h1>
                <hr className='aboutMeTitleLine' />
              </div>

              <p className="hobbiesText">
                <Typical
                  steps={['In my free time, I like to code. 💻', 3000, 'In my free time, I like to game. 🎮', 3000, 'In my free time, I like to have fun. 😄', 3000]}
                  loop={Infinity}
                />
              </p>
            </div>

            <img className='svgGraphic' src={HelloSVG} alt="Hello" />
          </div>

          <div className="waveWrapper">
            <div className="wave darkWave">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
              </svg>
            </div>
          </div>
        </div>
        
    </>
  )
}
